import React from 'react'
import iconOne from '../../assets/images/icon/1.png'
import iconTwo from '../../assets/images/icon/2.png'
import iconThree from '../../assets/images/icon/3.png'
import iconFour from '../../assets/images/icon/4.png'

function Features({heading, subHeading}) {
  return (
    <>
      <section
        className="appie-service-area appie-service-3-area pt-195 pb-100"
        id="service"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">{heading}</h3>
                <p>{subHeading}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-1
                wow
                animated
                fadeInUp
              "
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="icon">
                  <img src={iconOne} alt="" />
                </div>
                <h4 className="appie-title">BankID och Swish</h4>
                <p>
                  Enkelt att komma igång - du behöver bara verifiera dig via
                  BankID och swishar in det beloppet du vill investera i vald
                  portfölj.
                </p>
                <a href="#">
                  Läs mer <i className="fal fa-arrow-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-2
                wow
                animated
                fadeInUp
              "
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="icon">
                  <img src={iconTwo} alt="" />
                </div>
                <h4 className="appie-title">
                  Exponering mot hela kryptomarknaden
                </h4>
                <p>
                  Välj bland våra smarta portföljer och vad som passar dig bäst.
                  Vi jobbar aktivt med att förvalta
                </p>
                <a href="#">
                  Läs mer <i className="fal fa-arrow-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-3
                wow
                animated
                fadeInUp
              "
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="icon">
                  <img src={iconThree} alt="" />
                </div>
                <h4 className="appie-title">Stensäkert</h4>
                <p>
                  De privata nycklarna till dina investeringar hålls av flera av
                  de största molntjänsterna. Det är omöjligt för någon
                  utomstående att nå
                </p>
                <a href="#">
                  Läs mer <i className="fal fa-arrow-right" />
                </a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="
                appie-single-service appie-single-services-3
                text-center
                mt-30
                item-4
                wow
                animated
                fadeInUp
              "
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="icon">
                  <img src={iconFour} alt="" />
                </div>
                <h4 className="appie-title">Ta ut när du vill</h4>
                <p>
                  Med två enkla knapptryck kan du ta ut din investering direkt
                  till din bank i SEK. Eller till valfri digital plånbok
                </p>
                <a href="#">
                  Läs mer <i className="fal fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Features
